import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "src/components/common/Footer";
import Header from "src/components/common/Header";
import ThankyouForm from "src/components/thankyou/thankyouForm";
import ProductForm from "../../components/product/productForm";
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from "../../_config";

export default function ThankyouUser(props: any) {
  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${APPLICATION_NAME} : Thank You`;
    return () => {
      document.title = prevTitle;
    };
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className="container">
          <Header type="side" />
        </div>
        <ThankyouForm slug={props?.slug} collection={props?.collection} />
        <div className="container">
          <Footer type="side" />
        </div>
      </section>
    </React.Fragment>
  );
}
