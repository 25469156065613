import { API_BASE_URL, SOCKETURL } from './site_urls'

export const APPLICATION_NAME = 'Shopify-FrontEnd'
export const APPLICATION_GLOBAL_SLUG = 'shopify-mvp'
export const STORAGE = 'Shopify'
export const WALLET_ADDRESS = 'Wallet'
export const SELECTED_EVENT_STORAGE = `${APPLICATION_NAME}-Event`

export const INSIDERODEO_EVENT_STORAGE = `EXTERNAL-EVENT-FOR-OVER-AND-UNDER`

export const GUEST_USER_ID = `GUEST_USER_ID`

export const APP_USER_ID = `USER_ID`

export const RODEPICK_NEXT_GAME_IDS = `${APPLICATION_NAME}-NEXT-GAMES`

export const IS_SHOWING_ADD_TO_HOME_SCREEN_IOS = `${APPLICATION_NAME}-IOS`

export const PER_PAGE = 6;
export const WITHOUT_PER_PAGE = 99999;

export const FILE_BASE_PATH = ''
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_STAGE = process.env.REACT_APP_STAGE

export const DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: 'MM/dd/yyyy',
  MOMENT_FORMAT: 'MM/DD/yyyy'
}

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: 'Do MMMM, YYYY hh:mm a',
  DISPLAY_DAY_TIME: 'dddd hh:mm a'
}
export const TIME_CONFIG = {
  TIME_FORMAT: 'hh:mm a',
  TIME_INTERVALS: 5,
}


export const SOCKET_URL = SOCKETURL

export const URLS = {
  HOME: '/',
  LOGIN: '/login',
  COLLECTION: '/collection',
  LANDING: '/landing',
  BIDDING: '/bidding',
  GENESIS: '/genesis',
  PAYOUT: '/payout',
  PAYOUT_ADD_EDIT: '/payout',
  PAYOUT_LIST: '/payout-list',
  PRODUCT: '/product',
  PRODUCT_DETAILS: '/product/:contractAddress/:tokenId',
  UPLOAD_PRODUCT: '/upload-product',
  CHECKOUT: '/checkout',
  THANKYOU: '/thankyou',
  PREVIEW_UPLOAD: '/preview-upload',
  REGISTRATION: '/registration',
  OTP:'/otp',
  RODEOS: '/rodeos',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  // RESET_PASSWORD: '/reset-password',
  TV_IMAGE: '/tv-leaderboard/:gameId',
  FAILED: '/failed',
  USER: {
    DASHBOARD: '/dashboard',
    PROFILE: '/profile',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:token_value'
  },
  CMS: {
    ABOUT_US: '/cms/about-us',
    LEGAL: '/cms/legal',
    HOW_TO_PLAY: '/cms/how-to-play',
    TERM_OF_USE: '/cms/term-of-use',
  },
  EVENT: {
    PERFORMANCE: '/performance',
    LEADERBOARD: '/leaderboard',
    LIST: '/events',
    PLAYERS: '/players',
    EVENT_GAMES: '/event/:eventId',
    EVENT_GAMES_DETAILS: '/event/:eventId/:gameId',
    RIDER_LIST: '/event/:eventId/:gameId',
    LEADER_BOARD: {
      EVENT: '/result/:eventId',
      GAME: '/result/:eventId/:gameId',
    },
    PLAYER_DETAILS: '/player-details',
    PLAYER_STATUS:'/status'
  },
}

export const API_URL = {
  USER: {
    LOGIN: `${API_BASE_URL}login`,
    LOGIN_WITH_OTP: `${API_BASE_URL}login-with-otp`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}init`,
    UPDATE_PROFILE: `${API_BASE_URL}users/update`,
    REGISTRATION:`${API_BASE_URL}register`,
    LOGIN_WITH_EMAIL: `${API_BASE_URL}login-with-email`,
    FORGOT_PASSWORD: `${API_BASE_URL}password/email`,
    RESET_PASSWORD: `${API_BASE_URL}reset-password`
  },
  TV_IMAGE: {
    DETAILS: `${API_BASE_URL}over-under/leaderboard-tvimage`
  },
  EVENT: {
    GET_EVENT_LIST: `${API_BASE_URL}over-under/rodeo-event`,
    GET_EVENT_GAME: `${API_BASE_URL}over-under/rodeo-event-performances`,
    GET_EVENT_GAME_DETAILS: `${API_BASE_URL}rodeo-pick/rodeo-event/event-details`,
    PICK_RIDER: `${API_BASE_URL}rodeo-pick/rodeo-event/user-pick`,
    LEADERBOARD: `${API_BASE_URL}over-under/leader-board`,

    GET_UPCOMING_EVENT: `${API_BASE_URL}over-under/performance-events`,
    JOIN_EVENT_GAME: `${API_BASE_URL}rodeo-race/rodeo-event/join`,
    PLAYERS_LIST: `${API_BASE_URL}over-under/event-contestants`,
    PLAYERS_DETAILS: `${API_BASE_URL}over-under/contestant-details`,
    BETTING_POINT_SUBMIT: `${API_BASE_URL}over-under/save-bet`,
    SAVE_BET: `${API_BASE_URL}over-under/save-user-bet`,

  },
  CMS: {
    GET_CMS: `${API_BASE_URL}rodeo-race/get-cms`,
  },
  COMMON: {
    GENDER_LIST: `${API_BASE_URL}genders`,
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    SECRET_QUESTION_LIST: `${API_BASE_URL}secret/questions`,
    TOKEN: `${API_BASE_URL}users/auth`,
    INIT: `${API_BASE_URL}stores/init`,
    MINT_TODAY: `${API_BASE_URL}checkout/prepare`,
    SUBMIT_PAYMENT: `${API_BASE_URL}checkout/do`,
    PRODUCT_LIST: `${API_BASE_URL}nfts/user`,
    PRODUCTS_DETAILS: `${API_BASE_URL}nfts`,
    LOGOUT_USER: `${API_BASE_URL}users/logout`,
    UNLOCKABLE_CONTENT: `${API_BASE_URL}nfts`,
    GET_NONSE: `${API_BASE_URL}nonse`
  },
  COLLECTION: {
    COLLECTION_LIST: `${API_BASE_URL}collections`,
    UPDATE: `${API_BASE_URL}collections`,
    FILE: `${API_BASE_URL}media`,
    ADD: `${API_BASE_URL}collections`,
    GENESIS: `${API_BASE_URL}collections/genesis`,
    PRODUCT_FILE: `${API_BASE_URL}nfts/import`,
    PRODUCT_PREVIEW: `${API_BASE_URL}nfts`
  },
  CATEGORIES: {
    CATEGORIES_LIST: `${API_BASE_URL}categories`,
  },
  NETWORK: {
    NETWORK_LIST: `${API_BASE_URL}networks`,
  },
  PAYOUT: {
    PAYOUT_LIST: `${API_BASE_URL}payout-groups`,
    UPDATE: `${API_BASE_URL}payout-groups`,
    FILE: `${API_BASE_URL}media`,
    ADD: `${API_BASE_URL}payout-groups`,
    PAYOUT_STATUS: `${API_BASE_URL}collections`
  },

}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  GLOBAL: {
    SET_LOGIN_MODAL: 'ACTIONS/GLOBAL/SET_LOGIN_MODAL',
  },
  FORGOT_PASSWORD: {
    FORGOT_PASSWORD_STEP: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_STEP',
    FORGOT_PASSWORD_DATA: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_DATA',
  },
  COLLECTION: {
    COLLECTION_LIST: 'ACTIONS/COLLECTION/COLLECTION_LIST',
    UPDATE: 'ACTIONS/COLLECTION/UPDATE',
    FILE: 'ACTIONS/COLLECTION/FILE',
    ADD: 'ACTIONS/COLLECTION/ADD',
  },
  GENESIS : {
    GENESIS_DETAILS: 'ACTIONS/GENESIS/GENESIS_DETAILS'
  },

  PAYOUT: {
    PAYOUT_LIST: 'ACTIONS/PAYOUT/PAYOUT_LIST',
    UPDATE: 'ACTIONS/PAYOUT/UPDATE',
    FILE: 'ACTIONS/PAYOUT/FILE',
    ADD: 'ACTIONS/PAYOUT/ADD',
    PAYOUT_DETAILS: 'ACTIONS/PAYOUT/PAYOUT_DETAILS',

  },
  CATEGORIES: {
    CATEGORIES_LIST: 'ACTIONS/CATEGORIES/CATEGORIES_LIST'
  },
  NETWORK: {
    NETWORK_LIST: 'ACTIONS/NETWORK/NETWORK_LIST'
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    REGISTRATION: 'ACTIONS/USER/REGISTRATION',
    LOGIN_WITH_OTP: 'ACTIONS/USER/LOGIN_WITH_OTP',
    LOGIN_WITH_EMAIL: 'ACTIONS/USER/LOGIN_WITH_EMAIL',
    ME: 'ACTIONS/USER/ME',
    ONLINE_STATUS_CHANGE: 'ACTIONS/USER/ONLINE_STATUS_CHANGE',
    SOCKET_INSTANCE_CONTAINER: 'ACTIONS/USER/SOCKET_INSTANCE_CONTAINER',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PROFILE: 'ACTIONS/USER/PROFILE',
    GAME_ID: 'ACTIONS/USER/GAME_ID',
    FORGOT_PASSWORD: 'ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'ACTIONS/USER/RESET_PASSWORD'
  },
  EVENT: {
    GET_EVENT_LIST: 'ACTIONS/EVENT/GET_EVENT_LIST',
    // GET_UPCOMING_EVENT: 'ACTIONS/EVENT/GET_UPCOMING_EVENT',
    GET_EVENT_GAME: 'ACTIONS/EVENT/GET_EVENT_GAME',
    GET_EVENT_GAME_DETAILS: 'ACTIONS/EVENT/GET_EVENT_GAME_DETAILS',
    GET_STATUS_MESSAGE: 'ACTIONS/EVENT/GET_STATUS_MESSAGE',
    LEADERBOARD: 'ACTIONS/EVENT/LEADERBOARD',

    OPEN_WINING_MODAL: 'ACTIONS/EVENT/OPEN_WINING_MODAL',
    PLAYERS_LIST:'ACTIONS/EVENT/PLAYERS_LIST',
    PLAYERS_DETAILS:'ACTIONS/EVENT/PLAYERS_DETAILS',
    BETTING_POINT_SUBMIT: 'ACTIONS/EVENT/BETTING_POINT_SUBMIT',
    TOTAL_DATA: 'ACTIONS/EVENT/TOTAL_DATA',
    SET_BET_ID: 'ACTIONS/EVENT/SET_BET_ID',
    RESET_BET_ID: 'ACTIONS/EVENT/RESET_BET_ID',

    //SOCKET
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST: 'ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST',
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS: 'ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS',
    GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST: 'ACTIONS/EVENT/GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST',
    GET_SOCKET_RIDER_WITH_VOTE_DETAILS: 'ACTIONS/EVENT/GET_SOCKET_RIDER_WITH_VOTE_DETAILS',
    GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS: 'ACTIONS/EVENT/GET_WINING_SOCKET_DATA',

    GET_EVENT_LIST_GLOWING_SOCKET: 'ACTIONS/EVENT/GET_EVENT_LIST_GLOWING_SOCKET',

    //Route change EVENT
    NEXT_EVENT_FROM_ROUTE: 'ACTIONS/EVENT/NEXT_EVENT_FROM_ROUTE',
    GET_PLAYER_LIST_STATUS_UPDATE: 'ACTIONS/EVENT/GET_PLAYER_LIST_STATUS_UPDATE',
    GET_PLAYER_DETAILS_STATUS_UPDATE: 'ACTIONS/EVENT/GET_PLAYER_DETAILS_STATUS_UPDATE',
    GET_PLAYER_LIST_ALL_DATA_AND_STATUS_CHANGE_UPDATE: 'ACTIONS/EVENT/GET_PLAYER_LIST_ALL_DATA_AND_STATUS_CHANGE_UPDATE',
    GET_PLAYER_RESET_CONTESTANTS: 'ACTIONS/EVENT/GET_PLAYER_RESET_CONTESTANTS',
    GET_PLAYER_EMIT_SCORE: 'ACTIONS/EVENT/GET_PLAYER_EMIT_SCORE',
  },
  TV_IMAGE: {
    DETAILS: 'ACTIONS/TV_IMAGE/DETAILS'
  }
}

export const SAGA_ACTIONS = {
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGIN_WITH_OTP: 'SAGA_ACTIONS/USER/LOGIN_WITH_OTP',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    DETAILS: 'SAGA_ACTIONS/USER/DETAILS',
    UPDATE_PROFILE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE',
    REGISTRATION: 'SAGA_ACTIONS/USER/REGISTRATION',
    LOGIN_WITH_EMAIL: 'SAGA_ACTIONS/USER/LOGIN_WITH_EMAIL',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
  },
  EVENT: {
    GET_EVENT_LIST: 'SAGA_ACTIONS/EVENT/GET_EVENT_LIST',
    GET_EVENT_GAME: 'SAGA_ACTIONS/EVENT/GET_EVENT_GAME',
    GET_EVENT_GAME_DETAILS: 'SAGA_ACTIONS/EVENT/GET_EVENT_GAME_DETAILS',
    PICK_RIDER: 'SAGA_ACTIONS/EVENT/PICK_RIDER',
    LEADERBOARD: 'SAGA_ACTIONS/EVENT/LEADERBOARD',
    SAVE_BET: 'SAGA_ACTIONS/EVENT/SAVE_BET',

    GET_UPCOMING_EVENT: 'SAGA_ACTIONS/EVENT/GET_UPCOMING_EVENT',
    JOIN_EVENT_GAME: 'SAGA_ACTIONS/EVENT/JOIN_EVENT_GAME',
    PLAYERS_LIST: 'SAGA_ACTIONS/EVENT/PLAYERS_LIST',
    PLAYERS_DETAILS: 'SAGA_ACTIONS/EVENT/PLAYERS_DETAILS',
    BETTING_POINT_SUBMIT: 'SAGA_ACTIONS/EVENT/BETTING_POINT_SUBMIT',
    TOTAL_DATA: 'SAGA_ACTIONS/EVENT/TOTAL_DATA'
  },
  TV_IMAGE: {
    DETAILS: 'SAGA_ACTIONS/TV_IMAGE/DETAILS',
  },
  CMS: {
    GET_CMS: 'SAGA_ACTIONS/COMMON/GET_CMS',
  },
  COMMON: {
    GENDER_LIST: 'SAGA_ACTIONS/COMMON/GENDER_LIST',
    COUNTRY_LIST: 'SAGA_ACTIONS/COMMON/COUNTRY_LIST',
    SECRET_QUESTION_LIST: 'SAGA_ACTIONS/COMMON/SECRET_QUESTION_LIST',
    TOKEN:'SAGA_ACTIONS/COMMON/TOKEN',
    INIT: 'SAGA_ACTIONS/COMMON/INIT',
    MINT_TODAY: 'SAGA_ACTIONS/COMMON/MINT_TODAY',
    SUBMIT_PAYMENT: 'SAGA_ACTIONS/COMMON/SUBMIT_PAYMENT',
    PRODUCT_LIST: 'SAGA_ACTIONS/COMMON/PRODUCT_LIST',
    PRODUCT_DETAILS: 'SAGA_ACTIONS/COMMON/PRODUCT_DETAILS',
    LOGOUT_USER:'SAGA_ACTIONS/COMMON/LOGOUT_USER',
    NFT_UNLOCKABLE:'SAGA_ACTIONS/COMMON/NFT_UNLOCKABLE',
    GET_NONSE: 'SAGA_ACTIONS/COMMON/GET_NONSE'
  },
  COLLECTION: {
    COLLECTION_LIST: 'SAGA_ACTIONS/COLLECTION/COLLECTION_LIST',
    UPDATE: 'SAGA_ACTIONS/COLLECTION/UPDATE',
    FILE: 'SAGA_ACTIONS/COLLECTION/FILE',
    ADD: 'SAGA_ACTIONS/COLLECTION/ADD',
    ADD_GENESIS: 'SAGA_ACTIONS/COLLECTION/GENESIS/ADD',
    UPDATE_GENESIS: 'SAGA_ACTIONS/COLLECTION/GENESIS/UPDATE',
    PRODUCT_FILE: 'SAGA_ACTIONS/COLLECTION/PRODUCT_FILE',
    PREVIEW_FILE: 'SAGA_ACTIONS/COLLECTION/PREVIEW_FILE',
    CONFIRM_BULK: 'SAGA_ACTIONS/COLLECTION/CONFIRM_BULK',
  },
  CATEGORIES: {
    CATEGORIES_LIST: 'SAGA_ACTIONS/CATEGORIES/CATEGORIES_LIST',
  },
  NETWORK: {
    NETWORK_LIST: 'SAGA_ACTIONS/NETWORK/NETWORK_LIST',
  },
  PAYOUT: {
    PAYOUT_LIST: 'SAGA_ACTIONS/PAYOUT/PAYOUT_LIST',
    UPDATE: 'SAGA_ACTIONS/PAYOUT/UPDATE',
    FILE: 'SAGA_ACTIONS/PAYOUT/FILE',
    ADD: 'SAGA_ACTIONS/PAYOUT/ADD',
    PAYOUT_DETAILS: 'SAGA_ACTIONS/PAYOUT/PAYOUT_DETAILS',
    PAYOUT_STATUS: 'SAGA_ACTIONS/PAYOUT/PAYOUT_STATUS',
  },
}

export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'