import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { STORAGE, URLS } from "src/_config";
import { Link } from "react-router-dom";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useAppLoader } from "src/_common/hooks/actions/common/appLoaderHook";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { useCommonApi } from "src/_common/hooks/actions/commonApiCall/appCommonApiCallHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";

interface CollectionFormValues {
  purchaseCount: any
}

function BiddingForm() {
  const { control, handleSubmit, errors, register, watch, reset } =
    useForm<CollectionFormValues>({
      // resolver: yupResolver(CollectionSchema),
      defaultValues: {},
    });
  const history = useHistory();
  const useAppUserDetailsSelectors: any = useAppUserDetailsSelector();
  const { showLoader, hideLoader } = useAppLoader();
  const [getVal, setVal] = useState<any>(1);
  const commonApi = useCommonApi();
  const toast = useToaster();


  const goToProductPage = () => {
    history.push({
      pathname: URLS.PRODUCT,
    });
  };

  const increaseValue = () => {
    const increaseVal: any = getVal + 1;
    if (increaseVal <= useAppUserDetailsSelectors?.checkoutMaxBuyLimit) {
      setVal(increaseVal);
      if (increaseVal) {
        reset({
          purchaseCount: increaseVal,
        });
      }
    }
  };

  const decreaseValue = () => {
    const decreaseVal: any = getVal - 1;
    if (decreaseVal >= useAppUserDetailsSelectors?.checkoutMinBuyLimit) {
      setVal(decreaseVal);
      if (decreaseVal) {
        reset({
          purchaseCount: decreaseVal,
        });
      }
    }
  };

  useEffect(() => {
    hideLoader();
  }, []);

  const submitBidding = () => {
    const params: any = {
      quantity:getVal ? getVal : 0,
      collectionEntity: useAppUserDetailsSelectors?.collection?._id
    };

    commonApi.callMintToday(params,
      (message: string, resp: any) => {
        toast.success(message);
        history.push({
          pathname: URLS.CHECKOUT,
          state: { orderId:  resp?._id, amount: resp?.amount},
        });

      },
      (message: string) => {
        toast.error(message);
      })
    }

  return (
    // <form>
    <>
    <div className="container">
          <Header type="side" />
        </div>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div
                className="landing_graphic landing-page-background"
                style={{
                  background: `url(${
                    useAppUserDetailsSelectors?.brandingHeroImage ||
                    "../images/landing_bg.png"
                  }) no-repeat center center`,
                }}
              >
                <div className="container">
                  <div className="landing_contentbox">
                    <h2>{useAppUserDetailsSelectors?.collection?.name}</h2>
                    {useAppUserDetailsSelectors?.collection?.description ? (
              <p dangerouslySetInnerHTML={{ __html: useAppUserDetailsSelectors?.collection?.description }} />
            ) : null}
                    <div className="biding_starctrl">
                      <h4>Status</h4>
                      <div className="timer_list">
                        <ul>
                          <li className="w-100">
                            <h3>
                              {useAppUserDetailsSelectors?.stock?.purchased ||
                                0}{" "}
                              /{" "}
                              {useAppUserDetailsSelectors?.stock?.available ||
                                0}
                            </h3>
                            <p>ALREADY SOLD</p>
                            <div className="row">
                            <div className="col-sm-3 col-12">
                              <span className="login_login moves-spn" onClick={decreaseValue}>-</span>
                              </div>
                              <div className="col-sm-6 col-12">
                                <div className="login-text ">
                                  <div className="form-group">
                                    {/* <label htmlFor="exampleInputEmail1">
                                        Short Name{' '}
                                        <span className="text-danger">*</span>
                                      </label> */}
                                    <Controller
                                      defaultValue={
                                        useAppUserDetailsSelectors
                                          ?.checkoutMinBuyLimit || 0
                                      }
                                      control={control}
                                      name="purchaseCount"
                                      render={({
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          // error={errors.shortName}
                                          placeholder="Enter Short Name"
                                          disabled={true}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-3 col-12">
                                <span onClick={increaseValue} className="login_login moves-spn">+</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <Link to={URLS.PRODUCT} className="back-history"> */}
                    <button
                      className="btn login_login"
                      onClick={submitBidding}
                    >
                      Mint Your's Today!
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      { localStorage.getItem(STORAGE) &&
      <div className="container">
          <Footer type="" />
        </div>
      }

      {/* </form> */}
    </>
  );
}

export default BiddingForm;
