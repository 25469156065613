import { } from 'src/_common/interfaces/ApiReqRes';
import { SAGA_ACTIONS } from 'src/_config'
import { useApiCall } from '../common/appApiCallHook'

export function useCommonApi() {

  const callApi = useApiCall()

  const getGenderList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GENDER_LIST, null, onSuccess, onError);
  }
  const getCountryList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.COUNTRY_LIST, null, onSuccess, onError);
  }
  const getSecretQuestion = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SECRET_QUESTION_LIST, null, onSuccess, onError);
  }

  const getAllUserDatas = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.INIT, data, onSuccess, onError);
  }

  const getToken = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.TOKEN, data, onSuccess, onError);
}

const mintToday = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.MINT_TODAY, data, onSuccess, onError);
}

const submitPayment = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.SUBMIT_PAYMENT, data, onSuccess, onError);
}

const getProductList = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.PRODUCT_LIST, data, onSuccess, onError);
}

const getProductDetails = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.PRODUCT_DETAILS, data, onSuccess, onError);
}

const logoutUser = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.LOGOUT_USER, data, onSuccess, onError);
}

const nftUnlockableContent = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.NFT_UNLOCKABLE, data, onSuccess, onError);
};

const getNonse = (data: any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.COMMON.GET_NONSE, data, onSuccess, onError);
};
  

  
  return {
    callGetGenderList: getGenderList,
    callGetCountryList: getCountryList,
    callGetSecretQuestion: getSecretQuestion,
    callGetToken: getToken,
    callGetUsersDetails: getAllUserDatas,
    callMintToday: mintToday,
    callSubmitPayments: submitPayment,
    callProductList: getProductList,
    callProductDetails: getProductDetails,
    callLogoutUsers: logoutUser,
    callNftUnlockable: nftUnlockableContent,
    callGetNonse: getNonse

  }
}