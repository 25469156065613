import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useCommonApi } from "src/_common/hooks/actions/commonApiCall/appCommonApiCallHook";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { STORAGE, URLS } from "src/_config";
interface HeaderProps {
  type?: string;
}

function Header({ type }: HeaderProps) {
  const useAppUserDetailsSelectors:any= useAppUserDetailsSelector()
  const commonApi = useCommonApi();
  const [tokenVal, setToken] = useState<boolean>(false);
  const toast = useToaster();
  const history = useHistory();


  const logout = () => {
    localStorage.clear();
    history.push({
      pathname: URLS.LANDING,
    });
    // const params: any = {
    // };

    // commonApi.callLogoutUsers(params,
    //   (message: string, resp: any) => {
    //     toast.success(message);
    //     localStorage.clear();
    //     history.push('/landing')
    //   },
    //   (message: string) => {
    //     toast.error(message);
    //   })
    }

    const goToMyProduct = () => {
      history.push({
        pathname: URLS.PRODUCT,
      });
    }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
        <nav className="navbar navbar-expand-lg">
        <div className="container">
          {/* <a className="navbar-brand" href="#"> */}
          <Link to={URLS.HOME} className="back-history navbar-brand">
            <img src={useAppUserDetailsSelectors?.brandingLogo || '..images/shopify.png'}/>
          </Link>
            {/* <span>{useAppUserDetailsSelectors?.brandingTitle || ''}</span> */}
          {/* </a> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse   navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto">
              {/* <li className="nav-item">
                <a className="nav-link" href="#"><img src="images/cart_icon.png"/> <div className="cartadd_item">1</div></a>
              </li> */}
              <li className="nav-item dropdown pofile_link">
                {localStorage.getItem(STORAGE) &&
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://shopifydapp-store.dreamztesting.com/images/user.png"/>
                </a>
                }
                <ul className="dropdown-menu">
                  {/* <li><a className="dropdown-item" href="#">My Products</a></li> */}
                  <li><a className="dropdown-item" href="#" onClick={goToMyProduct}>My Product</a></li>
                  <li><a className="dropdown-item" href="#" onClick={logout}>Log Out</a></li>
                </ul>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#"><img src="images/3.png"/></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"><img src="images/twitter.png"/></a>
              </li> */}
            </ul>
            
          </div>
        </div>
    </nav>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
