import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { URLS } from "src/_config";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useCommonApi } from "src/_common/hooks/actions/commonApiCall/appCommonApiCallHook";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { Modal } from 'react-bootstrap';


interface ProductFormValues {}

function ProductDetailsForm(props: any) {
  const { control, handleSubmit, errors, register, watch } =
    useForm<ProductFormValues>({
      //   resolver: yupResolver(CollectionSchema),
      defaultValues: {},
    });
  let { tokenId, contractAddress } = useParams<any>();

  const useAppUserDetailsSelectors: any = useAppUserDetailsSelector();

  const history = useHistory();
  const location: any = useLocation();
  const commonApi = useCommonApi();
  const [getProductDetail, setProductDetails] = useState<any>("");
  const [showSensitiveCoverImg, setShowSensitiveCoverImg] = useState<boolean>(false);
  const [content, setContent] = useState({
    loading: true,
    isOwner: false,
    data: null,
    msg: null,
  });
  const [showUnlockModal, setShowUnlockModal] = useState<boolean>(false)
  const [unlockableContentText, setUnlockableContentText] = useState<any>('')


  useEffect(() => {
    console.log(tokenId, contractAddress)
    let loc: any = location;
    if (tokenId && contractAddress) {
      getProductDetails();
      // showContentHandler(contractAddress,tokenId)
    }
  }, []);

  const goToCheckoutPage = () => {
    history.push({
      pathname: URLS.CHECKOUT,
    });
  };

  const getProductDetails = () => {
    let loc: any = location;
    const params: any = {
      token_id: tokenId,
      contract_address: contractAddress,
    };

    commonApi.callProductDetails(
      params,
      (message: string, resp: any) => {
        setProductDetails(resp);
      },
      (message: string) => {
        // toast.error(message);
      }
    );
  };


  // this part for unlockable content


  const showContentHandler = () => {
    try {
    
        let paramsToSend = {
          contract_address: contractAddress, 
          token_id: tokenId,
        };

        console.log('paramsToSend', paramsToSend)
        console.log(useAppUserDetailsSelectors)

        commonApi.callNftUnlockable(
          paramsToSend,
          (message:any, resp:any) => {
            if(resp){
              setUnlockableContentText(resp?.unlockableText)
              openUnlockableContentModal()
            }
          
          },
          (message:any, error:any) => {
            
          }
        );
      // }
    } catch (err) {
      console.log('err')
    }
  };

  const openUnlockableContentModal = () =>{
    setShowUnlockModal(true)
  }

  const closeModal =() =>{
    setShowUnlockModal(false)
  }

  // <--------------this one for fav icon upload--------------------->

  // <--------------this one for network list--------------------->

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="innerpage_content">
              <div className="container">
                <div className="breadcamp">
                  <Link to={URLS.PRODUCT} className="back-history">
                    my products
                  </Link>
                </div>
                <h2 className="page_title">Product Details</h2>
                <h5 className="subtitle">{getProductDetail?.itemName}</h5>
                <div className="product_details">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      {/* <div
                        id="carouselExampleIndicators"
                        className="carousel slide productdetail_slide"
                        data-bs-ride="carousel"
                      >
                        
                        <div className="carousel-inner">
                          <div
                            className="carousel-item active"
                            onClick={goToCheckoutPage}
                          >
                            <img
                              src={
                                (getProductDetail &&
                                  getProductDetail?.logoImage?.fileUrl) ||
                                "../images/slider_01.jpg"
                              }
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                          
                        </div>

                        
                      </div> */}
                      {getProductDetail && getProductDetail?.explicit &&
                      <div className={+"nft-detail-img" + ((!showSensitiveCoverImg && getProductDetail?.explicit) ? " sensitive-img-cover" : '')} onClick={() => setShowSensitiveCoverImg(!showSensitiveCoverImg)}>
                                    <span className="cover-image"><img className="d-block w-100" src={useAppUserDetailsSelectors?.collection?.bannerImage?.fileUrl} /></span>                                    
                        </div>
                      }
                      {(showSensitiveCoverImg || !getProductDetail?.explicit) &&
                        <div className="nft-detail-img">
                          <img src={
                                (getProductDetail &&
                                  getProductDetail?.logoImage?.fileUrl) ||
                                "../images/slider_01.jpg"
                              } 
                              className="d-block w-100"
                              />                                   
                        </div>
                      }
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                      <h5 className="subtitle ml-2">{getProductDetail?.description}</h5>
                      </div>
                      <div className="row">
                        {getProductDetail &&
                        getProductDetail?.properties &&
                        getProductDetail?.properties?.length ? (
                          getProductDetail?.properties?.map(
                            (item: any, index: number) => (
                              <div className="col-lg-6 col-md-6 border-0" key={index}>
                                <div className="productdetail_list">
                                  {/* <p>{item?.key}</p> */}
                                  <h4>{item?.key}</h4>
                                  <h3>{item?.value}</h3>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <p>No Properties Found</p>
                        )}

                        {/* <div className="col-lg-6 col-md-6 border-0">
                                <div className="productdetail_list">
                                    <p>car</p>
                                    <h4>Yellow</h4>
                                    <h3>2% have this trait</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="productdetail_list">
                                    <p>trees</p>
                                    <h4>Green</h4>
                                    <h3>65% have this trait</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="productdetail_list">
                                    <p>stones</p>
                                    <h4>Gray</h4>
                                    <h3>15% have this trait</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="productdetail_list">
                                    <p>platform</p>
                                    <h4>Elevated</h4>
                                    <h3>10% have this trait</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="productdetail_list">
                                    <p>concept Style</p>
                                    <h4>3D</h4>
                                    <h3>50% have this trait</h3>
                                </div>
                            </div> */}
                      </div>
                      <div className="row">
                        {getProductDetail &&
                        getProductDetail?.levels &&
                        getProductDetail?.levels?.length ? (
                          getProductDetail?.levels?.map(
                            (item: any, index: number) => (
                              <div className="col-lg-6 col-md-6 border-0" key={index}>
                                <div className="productdetail_list">
                                  <h4>{item?.name}</h4>
                                  <h3>{item?.value_one}, {item?.value_two}</h3>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          // <p>No  Found</p>
                          null
                        )}
                      </div>
                      {getProductDetail?.unlockable &&
                      <div className="row">
                      <button
                      className="btn login_login mt-4 "
                      onClick={showContentHandler}
                    >
                      <i className="fa fa-lock" aria-hidden="true"></i>  Unlockable Content
                      </button>
                     </div>
                      }
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* </form> */}

      {showUnlockModal &&
      <React.Fragment>
            <Modal
                show={true}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='status-modal'
            >  
            <Modal.Header>
                    <div className="btn-wrap mb-3 bttn-cross-modl">
                            <img src="https://shopifydapp-store.dreamztesting.com/images/cross.png" onClick={() => closeModal()}
                            />
                    </div>
                </Modal.Header>             
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        {/* <h4 className="rider-name-content">{message}</h4> */}
                        {unlockableContentText? unlockableContentText : ''}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <div className="btn-wrap mb-3 ">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3 modal-close-bttn"
                            onClick={() => closeModal()}
                            >
                            Close
                        </button>
                    </div>
                </Modal.Footer> */}
            </Modal>
        </React.Fragment>
      }
    </>

    
  );
}

export default ProductDetailsForm;
