import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import { getNonse, getProductDetails, getProductList, getToken, getUserDetails, logoutUsers, submitMintToday, submitPayments, unLockableContent } from './commonSaga';

import { addCollection, addGenesis, confirmBulkUpload, getCategoriesList, getCollectionList, getFileProductView, getNetworkList, updateCollection, updateFile, updateGenesis, updateProductFile } from './collectionSaga';
import { addPayout, detailsPayout, editPayout, payoutStatusChangeForCollection } from './payoutSaga';
import { listPayout } from './payoutSaga';



export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.COLLECTION.COLLECTION_LIST, getCollectionList),
    takeLatest(SAGA_ACTIONS.CATEGORIES.CATEGORIES_LIST, getCategoriesList),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE, updateCollection),
    takeLatest(SAGA_ACTIONS.COLLECTION.FILE, updateFile),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD, addCollection),
    takeLatest(SAGA_ACTIONS.NETWORK.NETWORK_LIST, getNetworkList),
    takeLatest(SAGA_ACTIONS.PAYOUT.ADD, addPayout),
    takeLatest(SAGA_ACTIONS.PAYOUT.PAYOUT_LIST, listPayout),
    takeLatest(SAGA_ACTIONS.PAYOUT.PAYOUT_DETAILS, detailsPayout),
    takeLatest(SAGA_ACTIONS.PAYOUT.UPDATE, editPayout),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD_GENESIS, addGenesis),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_GENESIS, updateGenesis),
    takeLatest(SAGA_ACTIONS.PAYOUT.PAYOUT_STATUS, payoutStatusChangeForCollection),
    takeLatest(SAGA_ACTIONS.COLLECTION.PRODUCT_FILE, updateProductFile),
    takeLatest(SAGA_ACTIONS.COLLECTION.PREVIEW_FILE, getFileProductView),
    takeLatest(SAGA_ACTIONS.COLLECTION.CONFIRM_BULK, confirmBulkUpload),
    takeLatest(SAGA_ACTIONS.COMMON.TOKEN, getToken),
    takeLatest(SAGA_ACTIONS.COMMON.INIT, getUserDetails),
    takeLatest(SAGA_ACTIONS.COMMON.MINT_TODAY, submitMintToday),
    takeLatest(SAGA_ACTIONS.COMMON.SUBMIT_PAYMENT, submitPayments),
    takeLatest(SAGA_ACTIONS.COMMON.PRODUCT_LIST, getProductList),
    takeLatest(SAGA_ACTIONS.COMMON.PRODUCT_DETAILS, getProductDetails),
    takeLatest(SAGA_ACTIONS.COMMON.LOGOUT_USER, logoutUsers),
    takeLatest(SAGA_ACTIONS.COMMON.NFT_UNLOCKABLE, unLockableContent),
    takeLatest(SAGA_ACTIONS.COMMON.GET_NONSE, getNonse),

  ]);
}