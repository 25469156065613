import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { STORAGE, URLS } from "src/_config";
interface FooterProps {
  type?: string;
}

interface SocialState {
    key: string;
    link: string;
    icon: string;
    type: string;
  }

function Footer({ type }: FooterProps) {
    const useAppUserDetailsSelectors:any= useAppUserDetailsSelector()
    const [socialLinks, setSocial] = useState<SocialState[]>([]);

    const ALL_LINKS = [
        { key: 'socialUrlDiscord', icon: `https://shopifydapp-store.dreamztesting.com/images/3.png`, link: useAppUserDetailsSelectors?.socialUrlDiscord, type: 'img' },
        // { key: 'storeSocialMediaEmail', icon: 'fa fa-envelope', link: useAppUserDetailsSelectors?.storeSocialMediaEmail, type: 'icon' },
        { key: 'socialUrlInstagram', icon: 'https://shopifydapp-store.dreamztesting.com/images/instragram.png', link: useAppUserDetailsSelectors?.socialUrlInstagram, type: 'img' },
        // { key: 'socialUrlWebsite', icon: '../images/ok-.png', link: useAppUserDetailsSelectors?.socialUrlWebsite, type: 'img' },
        { key: 'socialUrlTwitter', icon: 'https://shopifydapp-store.dreamztesting.com/images/twitter.png', link: useAppUserDetailsSelectors?.socialUrlTwitter, type: 'img' },
        { key: 'socialUrlFacebook', icon: 'https://shopifydapp-store.dreamztesting.com/images/facebook.png', link: useAppUserDetailsSelectors?.socialUrlFacebook, type: 'img' },
        { key: 'socialUrlTikTok', icon:`https://shopifydapp-store.dreamztesting.com/images/tktk.png`, link: useAppUserDetailsSelectors?.socialUrlTikTok, type: 'img' },
      ];

     


  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
        <div className="container">
            <div className="footer_link">
                <ul>
                    {localStorage.getItem(STORAGE) &&
                        <>
                    <li>
                    <Link to={URLS.HOME} className="back-history">
                            Home
                    </Link>
                    </li>
                    <li>
                    <Link to={URLS.PRODUCT} className="back-history">
                            My Products
                    </Link>
                    </li>
                    </>
                    }
                    {/* <li>
                    <Link to={URLS.BIDDING} className="back-history">
                            Bidding
                    </Link>
                    </li> */}
                    {/* <li>
                        <a href="#">Link 2</a>
                    </li>
                    <li>
                        <a href="#">Link 3</a>
                    </li>
                    <li>
                        <a href="#">Link 4</a>
                    </li>
                    <li>
                        <a href="#">Link 5</a>
                    </li> */}
                </ul>
            </div>
            <div className="copyright_txt">
                <a href="#" className="copyright_link">© 2022 The {useAppUserDetailsSelectors?.brandingTitle}.</a>
                <ul className="footer_sociallink">
                    {/* <li><a href="#"><img src="../images/3.png"/></a></li>
                    <li><a href="#"><img src="../images/twitter.png"/></a></li> */}
                    {ALL_LINKS.length
                    ? ALL_LINKS
                        .filter((obj) => (obj.link != '' || obj.link != null))
                        .map((social, index: number) => (
                          <li key={index}>
                            <a target={'_blank'} href={social?.key==='storeSocialMediaEmail' ? `mailto:${social?.link}` : social?.link}>
                              {/* <a> */}
                                {social.type === 'img' ? social?.link ? <img src={social.icon}/> : ''  : social?.link ? <i className={social.icon} /> : ''}
                              {/* </a> */}
                            </a>
                          </li>
                        ))
                    : null}
                </ul>
            </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
