import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS, APP_USER_ID, GUEST_USER_ID } from "src/_config";
export interface EventReducer {
  getCollectionList: any;
  getCategoriesList: any;
  getGenesisDetails: any;


}

const initialState: EventReducer = {
  getCollectionList: null,
  getCategoriesList: null,
  getGenesisDetails: null
};

const eventReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
      case ACTIONS.COLLECTION.COLLECTION_LIST:
      return {
        ...state,
        getCollectionList: action.payload,
        total_data: action.total_data,
      };
      case ACTIONS.GENESIS.GENESIS_DETAILS:
      return {
        ...state,
        getGenesisDetails: action.payload,
        total_data: action.total_data,
      };

      // case ACTIONS.CATEGORIES.CATEGORIES_LIST:
      // return {
      //   ...state,
      //   getCollectionList: action.payload,
      //   total_data: action.total_data,
      // };
      //this one for default

    default:
      return state;
  }
};

export default eventReducer;
