// @ts-nocheck
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { STORAGE, URLS } from './_config';
import CacheBuster from './CacheBuster';
import CollectionUser from './containers/landingUser/landing';
import { useCommonApi } from './_common/hooks/actions/commonApiCall/appCommonApiCallHook';
import LandingUser from './containers/landingUser/landing';
import BiddingUser from './containers/landingBidding/bidding';
import ProductUser from './containers/product/product';
import ProductDetails from './containers/productDetails/productDetails';
import CheckoutUser from './containers/checkout/checkout';
import ThankyouUser from './containers/thankyou/thankyou';
import FailedUser from './containers/failed/failed';







function App() {
  const commonApi = useCommonApi();

useEffect(() => {
  getUserDetails()
},[])

const getUserDetails = () => {
  const params:any = { 
  };

  commonApi.callGetUsersDetails(
    params,
    (message: string, resp: any) => {
      console.log(resp)
    },
  );
};

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path={URLS.HOME} component={(LandingUser)} />
                <Route exact path={URLS.LANDING} component={(LandingUser)} />
                <Route exact path={URLS.BIDDING} component={(BiddingUser)} />
                <Route exact path={URLS.PRODUCT} component={(ProductUser)} />
                <Route exact path={URLS.PRODUCT_DETAILS} component={(ProductDetails)} />
                <Route exact path={URLS.CHECKOUT} component={(CheckoutUser)} />
                <Route exact path={URLS.THANKYOU} component={(ThankyouUser)} />
                <Route exact path={URLS.FAILED} component={(FailedUser)} />


              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
