import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "src/components/common/Footer";
import Header from "src/components/common/Header";
import LandingForm from "src/components/landing/landingForm";
// import Sidebar from 'src/components/common/Sidebar'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from "../../_config";

export default function LandingUser() {
  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${APPLICATION_NAME} : Landing`;
    return () => {
      document.title = prevTitle;
    };
  }, []);

  return (
    <React.Fragment>
      <section>
        <LandingForm/>
      </section>
    </React.Fragment>
  );
}
