import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { URLS } from "src/_config";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useCommonApi } from "src/_common/hooks/actions/commonApiCall/appCommonApiCallHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";


interface ProductFormValues {}

function CheckoutForm(props: any) {
  const { control, handleSubmit, errors, register, watch } =
    useForm<ProductFormValues>({
      //   resolver: yupResolver(CollectionSchema),
      defaultValues: {},
    });
    const history = useHistory();
    const commonApi = useCommonApi();
  const toast = useToaster();
  const location:any = useLocation();
  const useAppUserDetailsSelectors: any = useAppUserDetailsSelector();




  // <--------------this one for fav icon upload--------------------->

  // <--------------this one for network list--------------------->

  const goToThankyouPage=()=>{
    history.push({
      pathname: URLS.THANKYOU,
    });
  }

  useEffect(() => {
    
  }, [location]);


  const submitPayment = () => {
    let loc: any = location;
    const params: any = {
      orderId: loc?.state?.orderId
    };

    commonApi.callSubmitPayments(params,
      (message: string, resp: any) => {
        if(resp){
        toast.success(message);
        history.push({
          pathname: URLS.PRODUCT,
        });
      }
      else {
        history.push({
          pathname: URLS.FAILED,
        });
      }

      },
      (message: string) => {
        toast.error(message);
      })
    }

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
          <div className="innerpage_content">
        <div className="container">
            <div className="d-flex align-items-start checkout-page">
                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="" data-bs-toggle="pill" data-bs-target="#jk" type="button" role="tab" aria-controls="v-pills-cart" aria-selected="true"><span>1</span> My Cart</button>
                  <button className="nav-link" id="" data-bs-toggle="pill" data-bs-target="#gh" type="button" role="tab" aria-controls="v-pills-payment" aria-selected="false"><span>2</span> Payment</button>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-cart" role="tabpanel" aria-labelledby=" " >
                    <div className="cart_title">
                        <h3>{useAppUserDetailsSelectors?.collection?.name}</h3>
                        <h3 className="text-right"><span>$</span>{location ? (location?.state?.amount?.toFixed(2)) : 0.00}</h3>
                    </div>
                    {/* <div className="cart_content">
                        <p>Colour: Solar Green / Night Metallic / Grey Six</p>
                        <p> Size: 8.5</p>
                    </div> */}
                  </div>
                  <div className="tab-pane fade show active" id="v-pills-payment" role="tabpanel" aria-labelledby=" " >
                        <div className="payment_title">
                            <h4>Credit/Debit Card</h4>
                            <div className="carttype_list">
                                <ul>
                                    <li><a href="#"><img src="images/cart_01.png"/></a></li>
                                    <li><a href="#"><img src="images/cart_02.png"/></a></li>
                                    <li><a href="#"><img src="images/cart_03.png"/></a></li>
                                    <li><a href="#"><img src="images/cart_04.png"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="payment_formpart">
                            <div className="mb-3">
                                <label >Card Number<sup>*</sup></label>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-3">
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-3">
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-3">
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-3">
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label >Name on Card<sup>*</sup></label>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 relative">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label >Card Expiry<sup>*</sup></label>
                                        {/* <select name="" id="" className="form-control">
                                            <option value="">MM/YY</option>
                                        </select> */}
                                        <input type="text" className="form-control" placeholder="MM/YY" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label>CVV<sup>*</sup></label>
                                        <input type="password" className="form-control" placeholder="" />
                                    </div>
                                    <div className="form_infof">?</div>
                                </div>
                            </div>
                            {/* <div className="mb-3">
                                <label>Selected Payment Wallet</label>
                                <select className="selected_wallet">
                                    <option value="">0x2B6c10e57e17deA01d17B141790579cCB34CA971</option>
                                </select>
                            </div> */}
                            <div className="mb-3">
                                <button className="explore_product login_login" onClick={submitPayment}>Place Order <span></span></button>
                            </div>
                        </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
          </div>
        </div>
      </section>

      {/* </form> */}
    </>
  );
}

export default CheckoutForm;
