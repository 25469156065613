import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { URLS } from "src/_config";
import { Link, useHistory } from "react-router-dom";


interface ProductFormValues {}

function FailedForm(props: any) {
  const { control, handleSubmit, errors, register, watch } =
    useForm<ProductFormValues>({
      //   resolver: yupResolver(CollectionSchema),
      defaultValues: {},
    });
    const history = useHistory();


  // <--------------this one for fav icon upload--------------------->

  // <--------------this one for network list--------------------->

  const goToHomePage=()=>{
    history.push({
      pathname: URLS.HOME,
    });
  }

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
          <div className="innerpage_content">
        <div className="container">


            <div className="thankyou">
                {/* <div className="tankyou_image"><img src="images/tankyou.png"/></div> */}
                <h1>Failed</h1>
                <p>on your Purchase!</p>
                <button className="explore_product  login_login" onClick={goToHomePage}>Go To Homepage <span></span></button>
            </div>

            
        </div>
    </div>
          </div>
        </div>
      </section>

      {/* </form> */}
    </>
  );
}

export default FailedForm;
