import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE, WALLET_ADDRESS } from '../_config';
import { CallApi } from './api/callApi';

// export function* getGenderList(action: any): any {
//   try {
//     const data = action.payload;
//     const resp = yield call(CallApi.GET, API_URL.COMMON.GENDER_LIST, data, false);

//     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

//       action && action.callbackSuccess && action.callbackSuccess(resp.data);
//     } else {
//       action && action.callbackError && action.callbackError(resp.data);
//     }
//   } catch (e) {
//     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//   }
// }


export function* getToken(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COMMON.TOKEN}`, data, true);
        if (resp && resp.status==200) {
            localStorage.setItem(STORAGE, resp.data.data.token);
            localStorage.setItem(WALLET_ADDRESS, resp.data.data.user.walletAddress);
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}


export function* getUserDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, `${API_URL.COMMON.INIT}`, data?.parms, true);
        if (resp && resp.status==200) {
            yield put({
                type: ACTIONS.USER.ME,
                payload: resp.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



    



}


export function* submitMintToday(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COMMON.MINT_TODAY}`, data, true);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}

export function* submitPayments(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COMMON.SUBMIT_PAYMENT}`, data, true);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.CATEGORIES.CATEGORIES_LIST,
            //     payload: resp.data.data.data[0]
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



}

export function* getProductList(action: any): any {
    try {
        const data = action.payload;
        let collection_id=data?.collection_id
        delete data['collection_id']
        const resp = yield call(CallApi.GET, `${API_URL.COMMON.PRODUCT_LIST}`+"?fields=logoImage,nftPrice", data, true);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.USER.ME,
            //     payload: resp.data.data
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



    



}

export function* getProductDetails(action: any): any {
    try {
        const data = action.payload;
        let token_id=data?.token_id
        let contract_address=data?.contract_address
        delete data['token_id']
        delete data['contract_address']
        const resp = yield call(CallApi.GET, `${API_URL.COMMON.PRODUCTS_DETAILS}/${contract_address}/${token_id}`+'?fields=logoImage,categories,nftPrice', data, true);
        if (resp && resp.status==200) {
            // yield put({
            //     type: ACTIONS.USER.ME,
            //     payload: resp.data.data
            // })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



    



}

export function* logoutUsers(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, `${API_URL.COMMON.LOGOUT_USER}`, data, true);
        if (resp && resp.status==200) {
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



    



}

export function* unLockableContent(action: any): any {
    try {
        const data = action.payload;
        let contractAddress=data?.contract_address
        let tokenId=data?.token_id
        delete data['contract_address']
        delete data['token_id']
        const resp = yield call(CallApi.GET, `${API_URL.COMMON.UNLOCKABLE_CONTENT}/${contractAddress}/${tokenId}/unlockables`, data, true);
        if (resp && resp.status==200) {
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



    



}

export function* getNonse(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.COMMON.GET_NONSE}`, data, true);
        if (resp && resp.status==200) {
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }



    



}