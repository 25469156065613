import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { URLS } from "src/_config";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useCommonApi } from "src/_common/hooks/actions/commonApiCall/appCommonApiCallHook";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { PER_PAGE } from "src/_config";
import Pagination from 'react-js-pagination';
import * as qs from 'query-string';
import queryString from 'query-string';
import moment from 'moment';
import { filterUrlValues } from "src/_common/interfaces/common";



interface ProductFormValues {}

interface FormValues {
  name: string;
}

const resetValue = {
  name: '',
};

function ProductForm(props: any) {
  const { control, handleSubmit, errors, register, watch, reset } =
    useForm<ProductFormValues>({
      //   resolver: yupResolver(CollectionSchema),
      defaultValues: {},
    });
    const commonApi = useCommonApi();
    const useAppUserDetailsSelectors: any = useAppUserDetailsSelector();
    const [getProductData, setProductData] = useState<any>([]);
    const history = useHistory();
    const [activePage, setactivePage] = useState<number>(1);
    const [filtersFieldShowHide, setfiltersFieldShowHide] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [selectedIds, setSelectedIds] = useState<any>();
    const location = useLocation();
    const [newProctsData, setNewProctsData] = useState<any>([]);





  // <--------------this one for fav icon upload--------------------->

  // <--------------this one for network list--------------------->

  useEffect(() => {
    // getPayoutList()
    getProductList(1, 'first_time')
  }, [])

  const getProductList = (pageNumber:any, type:any) => {
    const params: any = {
      perPage: PER_PAGE,
    };

    if(type!='reset'){
    for (let [key, value] of Object.entries(queryString.parse(location.search))) {
      params[key] = value;
    }
  }

    params['page']=pageNumber
    params['collection_id']=useAppUserDetailsSelectors?.collection?._id


    console.log(params)

    commonApi.callProductList(
      params,
      (message: string, resp: any) => {
        if(resp){
          setProductData(resp?.data)
          setData(resp)
        }
      },
      (message: string) => {
        // toast.error(message);
      }
    );
  };


  // this one for filter data submit and set page 1 for pagination

  const onSubmit = (data: any) => {
    let params = { ...data };

    params = filterUrlValues(params)
    if (params.startDate) {
      params['startDate'] = moment(params.startDate).format('YYYY-MM-DD');
    }
    if (params.endDate) {
      params['endDate'] = moment(params.endDate).format('YYYY-MM-DD');
    }
    params['page'] = 1;
    params['perPage'] = PER_PAGE;
    setactivePage(1)
    history.push(URLS.PRODUCT + `?${qs.stringify(params)}`);
    params['collection_id']=useAppUserDetailsSelectors?.collection?._id
    commonApi.callProductList(
      params,
      (message: string, resp: any) => {
        if(resp){
          setProductData(resp?.data)
        }
      },
      (message: string) => {
        // toast.error(message);
      }
    );
  };

  // this one for reset  filter data 
  const resetData = () => {
    reset(resetValue)
    history.push(URLS.PRODUCT);
    let pageNumberFirstTime=1
    setactivePage(1)
    setTimeout(() => {
      getProductList(pageNumberFirstTime, 'reset')
    }, 500);
  };


  // if there have any params in url then it passes params through url 

  useEffect(() => {
    const queryVal:any=queryString.parse(location.search)
    if(queryVal &&  queryVal?.page){
      setactivePage(Number(queryVal?.page))
    reset(queryString.parse(location.search))
    console.log(queryString.parse(location.search))
    getProductList(queryVal?.page, 'first time')
    }
    else{
      getProductList(1, 'first time')
    }
  }, []);



  // pagination page change and set 

  const handlePageChange = (pageNumber: any) => {
    let temp = { ...queryString.parse(location.search) };
    temp['page']=pageNumber
    history.push(URLS.PRODUCT + `?${qs.stringify(temp)}`);
    console.log('temp', temp)
    console.log('pageNumber', pageNumber)
    setactivePage(pageNumber)
    getProductList(pageNumber, 'page change')
  };

  // if filter button click then show filter fields

  const filterShowHide = (flts:any) => {
    setfiltersFieldShowHide(flts)
  }

  const goToProductDetails = (items:any) => {
    history.push({
      pathname: `${URLS.PRODUCT}/${items?.contractAddress}/${items?.tokenId}`,
      state: { productId:  items?._id, token_id: items?.tokenId, contract_address: items?.contractAddress},
    });
  };

  const setShowSensitiveCoverImg=(item:any, index:any) =>{
    setSelectedIds(item?._id)
    for(let i=0; i<=getProductData?.length;i++){
      if(getProductData[i]?._id==item?._id){
        getProductData[i].explicit= false
      }
    }

    setNewProctsData(getProductData)

    if(newProctsData && newProctsData.length){
      console.log(newProctsData)
      setProductData(newProctsData)
    }
  }

  return (
    // <form>
    <>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="innerpage_content">
              <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                  <h2>My Products</h2>
                  <div className="d-flex align-items-center">
                    <div
                      className="cursor-pointer d-flex justify-content-between align-items-baseline ms-3 flter-blue-btn"
                      onClick={()=>filterShowHide(!filtersFieldShowHide)}
                    >
                      <i className="fa fa-filter" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                  {filtersFieldShowHide && 
              <div className="row mb-3">
                <div>
                  <div className="card">
                    <div
                      className=""
                    >
                      <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row mb-3">
                            <div className="col-lg-3 col-md-3 col-12 mt-mobile-2">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Name{' '}
                                </label>
                                <Controller
                                  defaultValue=""
                                  control={control}
                                  name="name"
                                  render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                      name={name}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      inputRef={ref}
                                      type="text"
                                      // error={errors.name}
                                      placeholder="Enter Name"
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            
                          </div>
                          <div className="d-flex justify-content-end mb-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnclours mx-2"
                            >
                              Filter
                            </button>

                            <button
                              type="button"
                              className="btn btn-danger btnclours"
                              onClick={resetData}
                            >
                              Clear
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }
                <div className="product_listing">
                  <div className="row">
                  {getProductData && getProductData?.length ? (
                      getProductData?.map((item: any, index: number) => (
                    <div className="col-lg-3 col-md-3 col-6" key={index}>
                      <div className="productlist_item">
                        <div className="product_image" 
                        // onClick={() => {
                        //         goToProductDetails(item)
                        //       }}
                              >
                        {/* <Link to={URLS.PRODUCT_DETAILS} className="back-history"> */}
                        {/* {item && item?.explicit && !selectedIds &&
                      <div className={"nft-detail-img sensitive-img-cover"} onClick={() => setShowSensitiveCoverImg(item, index)}>
                                    <span className="cover-image"><img className="d-block w-100" src={useAppUserDetailsSelectors?.collection?.bannerImage?.fileUrl} /></span>                                    
                        </div>
                        } */}
                        {((item && !item.explicit) || (selectedIds==item?._id)) ?
                          <img src={item?.logoImage?.fileUrl || 'images/product_01.jpg'} /> : 
                          <div className={"nft-detail-img sensitivess-imgss-coverss"} onClick={() => setShowSensitiveCoverImg(item, index)}>
                                    <span className="cover-image"><img className="d-block w-100" src={useAppUserDetailsSelectors?.collection?.bannerImage?.fileUrl} /></span>                                    
                        </div>
                        }
                        {/* </Link> */}
                        </div>
                        <h3>{item?.itemName}</h3>
                        <p>Current Price</p>
                        <h4>{item?.nftPrice?.price?.toFixed(2) || 0.00} (${item?.nftPrice?.priceUsd.toFixed(2) || 0.00})</h4>
                        <a className="explore_product login_login" onClick={() => {
                                goToProductDetails(item)
                              }}>
                          Explore <span></span>
                        </a>
                      </div>
                    </div>
                      ))) : <p>No Products Found</p>}
                  </div>
                </div>
                {data && data?.total > 0 ? (
              <div className="d-flex justify-content-end mt-3">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={PER_PAGE}
                  totalItemsCount={data.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  innerClass="pagination mt-3 font-link"
                  itemClass="paginate_button page-item"
                  linkClass="page-link"
                  activeClass="active"
                  hideFirstLastPages={true}
                  prevPageText="Previous"
                  nextPageText="Next"
                />
              </div>
            ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* </form> */}
    </>
  );
}

export default ProductForm;
