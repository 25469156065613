import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* addPayout(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, `${API_URL.PAYOUT.ADD}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* listPayout(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, `${API_URL.PAYOUT.PAYOUT_LIST}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* detailsPayout(action: any): any {
    const getSlug= localStorage.getItem('slug')
     const slug='avijit'
     try {
         const data = action.payload;
         const resp = yield call(CallApi.GET, `${API_URL.PAYOUT.PAYOUT_LIST}/${data?.id}`);
         if (resp && resp.status==200) {
             yield put({
                 type: ACTIONS.PAYOUT.PAYOUT_DETAILS,
                 payload: resp.data.data
             })
             // yield put({
             //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
             //     payload: resp.data.data.status
             // })
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
     } catch (e:any) {
         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
 }


 export function* editPayout(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, `${API_URL.PAYOUT.UPDATE}/${data?.payoutId}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* payoutStatusChangeForCollection(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, `${API_URL.PAYOUT.PAYOUT_STATUS}/${data?.collection_slug}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            // yield put({
            //     type: ACTIONS.EVENT.LEADERBOARD,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}