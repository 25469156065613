import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { STORAGE, URLS } from "src/_config";
import { Link, useHistory } from "react-router-dom";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { useCommonApi } from "src/_common/hooks/actions/commonApiCall/appCommonApiCallHook";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";

interface CollectionFormValues {}

function LandingForm() {
  const { control, handleSubmit, errors, register, watch } =
    useForm<CollectionFormValues>({
      defaultValues: {},
    });
  const useAppUserDetailsSelectors: any = useAppUserDetailsSelector();
  const history = useHistory();
  const [expiryTime, setExpiryTime] = useState<any>('');
  const [countdownTime, setCountdownTime] = useState<any>({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });
  const commonApi = useCommonApi();
  const [tokenVal, setToken] = useState<boolean>(false);
  const [getCurrentDateCheck, setCurrentDateCheck] = useState<boolean>(false);
  const toast = useToaster();

  useEffect(() => {
    getUserDetails()
  },[])
  
  const getUserDetails = () => {
    const params:any = { 
    };
  
    commonApi.callGetUsersDetails(
      params,
      (message: string, resp: any) => {
        if(resp && resp?.release?.date){
        countdownTimer(resp?.release?.date)
        }

      const countdownDateTime = new Date(resp?.release?.date).getTime();
      const currentTime = new Date().getTime();

      if(countdownDateTime<currentTime){
        setCurrentDateCheck(true)
      }
      else{
        setCurrentDateCheck(false)
      }
      },
        (message: string) => {
          // toast.error(message);
        }
    );
  };

  const countdownTimer = (releaseDate:any) => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(releaseDate).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  const getToken = () => {
    const params: any = {
      isCustomer: true
    };

    commonApi.callGetToken(params,
      (message: string, resp: any) => {
        if(resp?.token){
        setToken(true)
        toast.success(message);
        history.push('/bidding')
        }

      },
      (message: string) => {
        toast.error(message);
      })
    }

    const goToBiddingPage=() => {
      history.push({
        pathname: URLS.BIDDING,
      });
    }

  return (
    // <form>
    <>
      <div className="container">
        <Header type="side" />
      </div>
      <section className="inner-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div
                className="landing_graphic landing-page-background"
                style={{
                  background: `url(${
                    useAppUserDetailsSelectors?.brandingHeroImage ||
                    "../images/landing_bg.png"
                  }) no-repeat center center`,
                }}
              >
                <div className="container">
                  <div className="landing_contentbox" >
                    <h2>{useAppUserDetailsSelectors?.collection?.name}</h2>
                    <span>
                    {/* <p>{useAppUserDetailsSelectors?.collection?.description}</p> */}
                    {useAppUserDetailsSelectors?.collection?.description ? (
              <p dangerouslySetInnerHTML={{ __html: useAppUserDetailsSelectors?.collection?.description }} />
            ) : null}
                    </span>
                    {useAppUserDetailsSelectors &&
                    useAppUserDetailsSelectors?.collection ? (
                      <div className="biding_starctrl">
                        <h4>{!getCurrentDateCheck ? 'Bidding Starts In' : 'Timer Stopped! Check Genesis Date'}</h4>
                        {expiryTime !== false ? (
                          <div className="timer_list">
                            <ul>
                              <li>
                                <h3>{countdownTime?.countdownDays}</h3>
                                <p>DAYS</p>
                              </li>
                              <li>
                                <h3>{countdownTime?.countdownHours}</h3>
                                <p>HOUR</p>
                              </li>
                              <li>
                                <h3>{countdownTime.countdownMinutes}</h3>
                                <p>MINS</p>
                              </li>
                              <li>
                                <h3>{countdownTime.countdownSeconds}</h3>
                                <p>SECS</p>
                              </li>
                            </ul>
                          </div>
                        ) : // (history.push('/bidding'))
                        null}
                      </div>
                    ) : (
                      <div className="biding_starctrl">
                      <div className="timer_list">
                        <h4>Coming Soon</h4>
                      </div>
                      </div>
                    )}
                    {/* <Link to={URLS.BIDDING} className="back-history"> */}
                    {localStorage.getItem(STORAGE) ? (
                        <button className="btn login_login" onClick={goToBiddingPage}>
                        Next
                    </button>
                    ):
                    (
                      <>
                      {useAppUserDetailsSelectors &&
                      useAppUserDetailsSelectors?.collection && !getCurrentDateCheck &&
                      <button className="btn login_login" onClick={getToken}>
                          Log In
                        </button>
                      }
                      </>
                    )
                    }
                    {/* <div className="new_usertxt">
                      New User? <a href="#">Register Now!</a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* {(localStorage.getItem(STORAGE) || (tokenVal)) && */}
      <div className="container">
        <Footer type="" />
      </div>
      {/* } */}

      {/* </form> */}
    </>
  );
}

export default LandingForm;
